import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { ResetPwdComponent } from './resetpassword/resetpwd.component';
import { SetPwdComponent } from './setpassword/setpwd.component';
import { AuthGuard } from './_guards';
import { RegisterComponent } from './register/register.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MywinHomeComponent } from './mywin/mywin-home/mywin-home.component';
import { MywinuserProfileComponent } from './mywin/mywinuser-profile/mywinuser-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DocumentViewComponent } from './document-view/document-view.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'subscribe', component: SubscribeComponent },
  // { path: 'welcome', component: WelcomeComponent, canActivate: [AuthGuard] },  
  { path: 'setpassword', component: SetPwdComponent },
  { path: 'resetpassword', component: ResetPwdComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'individual', loadChildren: () => import('./individual/individual.module').then(m => m.IndividualModule) },
  { path: 'customer', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },
  { path: 'oem', loadChildren: () => import('./oem/oem.module').then(m => m.OemModule) },
  { path: 'mywin-user', component: MywinHomeComponent, canActivate: [AuthGuard] },
  { path: 'mywinuser-profile', component: MywinuserProfileComponent, canActivate: [AuthGuard] },
  { path: 'document-view', component: DocumentViewComponent, canActivate: [AuthGuard] },

  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
