import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor(
        private currencyPipe: CurrencyPipe,
    ) { }

    MIME_TYPES = [
        // { extension: '.aac', mime_type: 'audio/aac' },
        { extension: '.mp4', mime_type: 'video/mp4' },
        { extension: '.webm', mime_type: 'video/webm' },
        { extension: '.ogg', mime_type: 'video/ogg' },
        { extension: '.mov', mime_type: 'video/mov' },
        { extension: '.wmv', mime_type: 'video/wmv' },
        { extension: '.wmv', mime_type: 'video/x-ms-wmv' },        
        { extension: '.wmv', mime_type: 'video/x-msvideo' },
        { extension: '.avi', mime_type: 'video/avi' },
        { extension: '.mpeg', mime_type: 'video/mpeg' },
        { extension: '.ogv', mime_type: 'video/ogg' },
        { extension: '.3gp', mime_type: 'video/3gpp' },
        { extension: '.bmp', mime_type: 'image/bmp' },
        { extension: '.csv', mime_type: 'text/csv' },
        { extension: '.doc', mime_type: 'application/msword' },
        { extension: '.docx', mime_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
        { extension: '.ico', mime_type: 'image/vnd.microsoft.icon' },
        { extension: '.jpeg', mime_type: 'image/jpeg' },
        { extension: '.jpg', mime_type: 'image/jpeg' },
        // { extension: '.mp3', mime_type: 'audio/mpeg' },
        { extension: '.odp', mime_type: 'application/vnd.oasis.opendocument.presentation' },
        { extension: '.ods', mime_type: 'application/vnd.oasis.opendocument.spreadsheet' },
        { extension: '.odt', mime_type: 'application/vnd.oasis.opendocument.text' },
        { extension: '.png', mime_type: 'image/png' },
        { extension: '.pdf', mime_type: 'application/pdf' },
        { extension: '.ppt', mime_type: 'application/vnd.ms-powerpoint' },
        { extension: '.pptx', mime_type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
        { extension: '.svg', mime_type: 'image/svg+xml' },
        // { extension: '.txt', mime_type: 'text/plain' },
        { extension: '.xls', mime_type: 'application/vnd.ms-excel' },
        { extension: '.xlsx', mime_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        { extension: '.dwg', mime_type: 'image/x-dwg' },  // AutoCAD Drawing Format
        { extension: '.dxf', mime_type: 'image/x-dxf' },  // AutoCAD Drawing Interchange Format
        { extension: '.dwf', mime_type: 'drawing/x-dwf' },  // AutoCAD Design Web Format
    ];

    getBase64FromUrl(url): any {
        return new Promise((resolve, reject) => {

            let img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = url;
            img.onload = () => {
                var canvas: HTMLCanvasElement = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
                // This will draw image
                ctx.drawImage(img, 0, 0);
                // Convert the drawn image to Data URL
                let dataURL: string = canvas.toDataURL("image/png");
                resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
            };

            var reader = new FileReader();
            reader.readAsDataURL(url);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    getBase64FromFile(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        });
    }

    isInteger(value: any): boolean {
        if (!value) return false;
        return !isNaN(parseInt(value)) && isFinite(value) && !value.toString().includes('.');
    }

    isDecimal(value: any): boolean {
        if (!value) return false;
        return !isNaN(parseInt(value)) && isFinite(value);
    }

    isValidFile(file_type, file_ext = ''): boolean {
        if (this.MIME_TYPES.some(x => x.mime_type == file_type.toLowerCase()) ||
            (!file_type && this.MIME_TYPES.some(x => x.extension == '.' + file_ext.toLowerCase()))) {
            return true;
        }
        return false;
    }

    isImageFile(file_type, file_ext = ''): boolean {
        if (this.MIME_TYPES.some(x => x.mime_type == file_type.toLowerCase()) && file_type.toLowerCase().includes('image')) {
            return true;
        }
        return false;
    }

    isVideoFile(file_type, file_ext = ''): boolean {
        if (this.MIME_TYPES.some(x => x.mime_type == file_type.toLowerCase()) && file_type.toLowerCase().includes('video')) {
            return true;
        }
        return false;
    }

    getExtensionType(document_name) {
        if (!document_name) return '/assets/svg-icon/ic_file.svg';
        let filename = document_name.substring(document_name.lastIndexOf(".") + 1)

        let img_ext = this.MIME_TYPES.filter(x => x.mime_type.includes('image')).map(x => x.extension.slice(1)) //["jpg", "jpeg", "jpe", "jpge", "png", "gif", "bmp", "svg"];
        let excel_ext = ["xlsx", "xls"];
        let video_ext = this.MIME_TYPES.filter(x => x.mime_type.includes('video')).map(x => x.extension.slice(1)) //["avi", "mp4", "mpeg", "ogv", "3gp"]
        let msword_ext = ["doc", "docx"]
        let powerpoint_ext = ["ppt", "pptx"]

        let extension;
        if (filename == "pdf") {
            extension = '/assets/svg-icon/ic_file_pdf.svg';
        } else if (msword_ext.includes(filename)) {
            extension = '/assets/svg-icon/ic_file_word.svg';
        } else if (excel_ext.includes(filename)) {
            extension = '/assets/svg-icon/ic_file_excel.svg';
        } else if (img_ext.includes(filename)) {
            extension = '/assets/svg-icon/ic_file_img.svg';
        } else if (powerpoint_ext.includes(filename)) {
            extension = '/assets/svg-icon/ic_file_power_point.svg';
        } else if (video_ext.includes(filename)) {
            extension = '/assets/svg-icon/ic_file_video.svg';
        } else {
            extension = '/assets/svg-icon/ic_file.svg';
        }
        return extension;
    }

    setCurrencyFormat(value, currency_code) {
        if (value) {
            return this.currencyPipe.transform(value.toString().replace(/\D/g, '').replace(/^0+/, ''), currency_code, 'symbol', '1.0-0');
        }
    }

    isVideoFileBlob(file_name): boolean {
        const video_formats = this.MIME_TYPES.filter(x => x.mime_type.includes('video')).map(x => x.extension.slice(1)) //['avi', 'mp4', 'mpeg', 'ogv', '3gp', 'webm'];
        let ext = file_name.split('.').pop();
        return video_formats.some(x => x == ext);
    }

    isImageFileBlob(file_name): boolean {
        const image_formats = this.MIME_TYPES.filter(x => x.mime_type.includes('image')).map(x => x.extension.slice(1)) //["jpg", "jpeg", "jpe", "jpge", "png", "gif", "bmp", "svg", "jfif"];
        let ext = file_name.split('.').pop();
        return image_formats.some(x => x == ext);
    }
}
