import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/service/alert.service';
import { Business } from 'src/app/service/Business.service';
import { HelperService } from 'src/app/service/helper.service';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { DocumentService } from 'src/app/service/document.service';

@Component({
  selector: 'app-ew-file-upload2',
  templateUrl: './ew-file-upload2.component.html',
  styleUrls: ['./ew-file-upload2.component.scss']
})
export class EwFileUpload2Component implements OnInit {
  @Input() is_view = false;
  @Input() set in_files(files: any[]) {
    if (files && files instanceof Array) {
      this.out_files = [...files];
    }
  }

  @Input() set read_only(value: any) {
    this._read_only = value;
  }

  @Input() set is_multiple(value: any) {
    this._is_multiple = value;
  }

  @Input() set show_file_label(value: any) {
    this._show_file_label = value;
  }

  @Input() set min_height(value: any) {
    this._min_height = value;
  }

  @Input() set inline_view(value: any) {
    this._inline_view = value;
  }


  @Input() set single_upload(value: any) {
    this._single_upload = value;
  }

  @Output() upload = new EventEmitter<any>();

  uploaded_files = [];
  uploaded_files_error = [];
  upload_mode = true;
  out_files = [];
  _read_only = false;
  doc_uploading = false;
  _is_multiple = true;
  file_label = '';
  file_label_error = false;
  _show_file_label = false;
  _min_height = '290';
  _inline_view = false;
  _single_upload = false;


  constructor(
    public helper: HelperService,
    private alertService: AlertService,
    private confirmDialog: ConfirmDialogService,
    private businessService: Business,
    private router: Router,
    private documentService: DocumentService
  ) { }

  ngOnInit(): void {
  }

  onDocumentChange(files) {
    this.upload_mode = false;
    this.uploaded_files_error = [];
    this.uploaded_files = Array.from(files);
    this.uploaded_files.forEach(f => {
      const err_obj = { name: f.name, size_limit: false, type: false };
      if (f.size > (1024 * 1024 * 100)) {
        err_obj.size_limit = true;
        this.uploaded_files_error.push(err_obj);
      }
      const file_ext = f.name.split('.').pop();
      if (!this.helper.isValidFile(f.type, file_ext)) {
        err_obj.type = true
        this.uploaded_files_error.push(err_obj);
      }
    });
  }

  onRemoveUploadedFile(file, event: Event) {
    this.uploaded_files = [...this.uploaded_files.filter(x => x.name != file.name)];
    if (this.uploaded_files.length === 0) {
      this.upload_mode = true;
    }
  }


  onDocumentAdd(is_install_base = false) {
    this.file_label_error = false;
    if (this._show_file_label && !this.file_label) {
      this.alertService.error('File label is required');
      this.file_label_error = true;
      return;
    }
    if (this.uploaded_files.length === 0) {
      this.alertService.error('Upload the document to add');
      return;
    }
    if (this.uploaded_files_error.length > 0) {
      this.alertService.error('Some of the uploaded files are not valid or exceeds maximum size limit');
      return;
    }

    let doc_uploads$ = [];
    this.doc_uploading = true;
    this.uploaded_files.forEach(file => {
      const obj = {
        file: file, document_type: '6', document_label: this.file_label,
        document_label_fk: null, document_label_ids: []
      };
      doc_uploads$.push(this.businessService.uploadEWDocument(obj));
    });
    forkJoin(doc_uploads$).subscribe((resp) => {
      this.out_files.unshift(...resp);
      this.upload.emit(this.out_files);
      this.doc_uploading = false;
      this.uploaded_files = [];
      this.upload_mode = true;
      setTimeout(() => {
        (document.getElementById('sol-doc-input') as any).value = null;
      }, 100);
      this.file_label = '';
    }, () => this.doc_uploading = false);

    // const file_data_promises = this.uploaded_files.map(f => this.helper.getBase64FromFile(f));
    // Promise.all(file_data_promises).then(datas => {
    //   datas.forEach((data, i) => {
    //     let new_file = {
    //       file: this.uploaded_files[i], file_data: data,
    //       file_name: this.uploaded_files[i].name, size: Math.ceil(this.uploaded_files[i].size / 1024),
    //     };
    //     this.out_files.unshift(new_file);
    //   });
    //   this.uploaded_files = [];
    //   this.upload.emit(this.out_files);
    //   (document.getElementById('sol-doc-input') as any).value = null;
    // });
    // this.upload_mode = true;
  }

  getFileSize(size) {
    return Math.ceil(size / 1024) + 'kb';
  }


  onRemoveItemDoc(doc) {
    this.confirmDialog.showConfirm(`Are you sure you want to remove document "${doc.file_name || doc.document_label}" ?`)
      .pipe(first()).subscribe((status) => {
        if (status) {
          doc.status = 'delete';
          const existing_docs = this.out_files.filter(x => x?.id);
          const filtered_docs = this.out_files.filter(x => !x?.id).filter(x => x.status != 'delete');
          this.out_files = [...existing_docs, ...filtered_docs];
          this.upload.emit(this.out_files);
        }
      });
  }

  getNotDeletedFilesCount() {
    return this.out_files.filter(x => x?.status != 'delete').length;
  }

  logDownload(id) {

  }

  downloadDocument(doc) {

  }

  onDocumentClick(doc) {
    // let url = this.router.createUrlTree(['/document-view'], { queryParams: { blob_name: doc?.blob_name } });
    // window.open(this.router.serializeUrl(url), '_blank');
    this.documentService.openDocumentInPage(doc?.blob_name);
  }

  getFileLabelExtension(doc) {
    return `${doc.document_label}.${doc.file_name.split('.').pop()}`;
  }

}
