<!-- <div class="">
    <button class="btn btn-outline-primary btn-sm" (click)="onClose()">Close</button>
    <div *ngIf="blob_url || pdf_data" class="position-relative">
        <ng-container *ngIf="!(is_video_file || is_image_file || is_pdf_file)">
            <iframe [src]="blob_url | safe" style="width:100%;height:100vh;"></iframe>
        </ng-container>
        <ng-container *ngIf="is_video_file">
            <div class="text-center mt-3">
                <video width="80%" height="80%" autoplay controls>
                    <source [src]="blob_url | safe" type="video/mp4">
                    <source [src]="blob_url | safe" type="video/ogg">
                    <source [src]="blob_url | safe" type="video/mpeg">
                    <source [src]="blob_url | safe" type="video/x-msvideo">
                    <source [src]="blob_url | safe" type="video/3gpp">
                    Your browser does not support the video tag.
                </video>
            </div>
        </ng-container>
        <ng-container *ngIf="is_image_file">
            <div class="image-container">
                <img [src]="blob_url | safe" class="no-blurry" (load)="onImageLoad()">
            </div>
        </ng-container>
        <ng-container *ngIf="is_pdf_file && pdf_data">
            <pdf-viewer [src]="pdf_data" [render-text]="true" [original-size]="false"
                style="width: 100%; height: 90vh;"></pdf-viewer>
        </ng-container>
    </div>
    <div *ngIf="!(blob_url || pdf_data)" class="text-bold text-center p-3">
        <p *ngIf="!loading" class="text-danger">No document found!</p>
        <p *ngIf="loading" class="text-secondary">Loading...</p>
    </div>
</div> -->

<div class="document-view-container">
    <div class="form-group d-flex">
        <div class="page-title flex-grow-1">
            {{blob_name?.split('-').slice(5).join('-')}}
        </div>
        <button class="btn btn-outline-primary btn-sm" (click)="onClose()">Close</button>
    </div>
    <div *ngIf="blob_url || pdf_data" class="">
        <!-- <div class="dc-misc"></div> -->
        <ng-container *ngIf="!(is_video_file || is_image_file || is_pdf_file)">
            <iframe [src]="blob_url | safe" style="width:100%;height:100vh;"></iframe>
        </ng-container>
        <ng-container *ngIf="is_video_file">
            <div class="text-center mt-3">
                <video width="80%" height="80%" autoplay controls>
                    <source [src]="blob_url | safe" type="video/mp4">
                    <source [src]="blob_url | safe" type="video/ogg">
                    <source [src]="blob_url | safe" type="video/mpeg">
                    <source [src]="blob_url | safe" type="video/x-msvideo">
                    <source [src]="blob_url | safe" type="video/3gpp">
                    Your browser does not support the video tag.
                </video>
            </div>
        </ng-container>
        <ng-container *ngIf="is_image_file">
            <div class="text-center" style="height: 100vh;">
                <img [src]="blob_url | safe" class="no-blurry" (load)="onImageLoad()">
            </div>
        </ng-container>
        <ng-container *ngIf="is_pdf_file && pdf_data">
            <pdf-viewer [src]="pdf_data" [render-text]="true" [original-size]="false"
                style="width: 100%; height: 90vh;"></pdf-viewer>
        </ng-container>
    </div>
    <div *ngIf="!(blob_url || pdf_data)" class="text-bold text-center p-3">
        <p *ngIf="!loading" class="text-danger">No document found!</p>
        <p *ngIf="loading" class="text-secondary">Loading...</p>
    </div>
</div>